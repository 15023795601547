import gql from 'graphql-tag';

const queries = {
  checkHealth: gql`
    query checkHealth {
      health
    }
  `,
  getCategories: gql`
    query categories {
      categories {
        id
        name
        en
        es
      }
    }
  `,
  getForgotPassword: gql`
    query getForgotPassword($email: String!) {
      forgotPassword(email: $email) {
        id
      }
    }
  `,
  generateAuthorizationCode: gql`
    mutation generateAuthorizationCode($client_id: String!) {
      generateAuthorizationCode(client_id: $client_id)
    }
  `,
  getCourses: gql`
    query getCourses {
      courses {
        id
        title
        description
        stages {
          id
          title
          description
        }
      }
    }
  `,
  getCourseByID: gql`
    query getCourseByID($id: ID!) {
      courses(where: { id: $id }) {
        id
        title
        description
        published
        goal
        student_upload
        sequential_stages
        peer_review
        available_throughout_faculty
        has_everyone_evaluate_stages
        score
        default_language
        available_languages
        categories {
          id
          es
          en
        }
        active_students {
          id
        }
        stages {
          id
          title
          description
          exercise {
            id
            description
          }
        }
        admin {
          id
          name
          lastname
          profile_image_link
        }
        teachers {
          id
        }
        pending_requests {
          user_id
        }
        student_upload
        course_preview_images {
          id
          link
        }
      }
    }
  `,
  getStageOfCourseByID: gql`
    query getStageOfCourseByID($id: ID!) {
      stages(where: { id: $id }) {
        id
        title
        description
      }
    }
  `,
  getCurrentSearchText: gql`
    query getCurrentSearchText {
      searchText @client
    }
  `,
  updateSearchText: gql`
    mutation updateSearchText($newSearchText: String!) {
      updateSearchText(newSearchText: $newSearchText) @client {
        searchText
      }
    }
  `,
  getCategoryFilter: gql`
    query getCategoryFilter {
      categoryFilter @client
    }
  `,
  updateCategoryFilter: gql`
    mutation updateCategoryFilter($newCategoryFilter: ID!) {
      updateCategoryFilter(newCategoryFilter: $newCategoryFilter) @client {
        newCategoryFilter
      }
    }
  `,
  searchInstitutions: gql`
    query institutions(
      $search_text: String
      $offset: Int
      $limit: Int
      $where: InstitutionFilterProvider
    ) {
      institutions(
        search_text: $search_text
        where: $where
        limit: $limit
        offset: $offset
      ) {
        id
        name
        image
        profile_image_link
        role_id
      }
    }
  `,

  totalInstitutions: gql`
    query totalInstitutions {
      totalInstitutions
    }
  `,

  searchCourses: gql`
    query courses(
      $search_text: String
      $offset: Int
      $limit: Int
      $where: CourseFilterProvider
    ) {
      courses(
        search_text: $search_text
        where: $where
        limit: $limit
        offset: $offset
      ) {
        id
        title
        description
        admissions_fee
        published
        default_language
        available_languages
        lms_course_id
        categories {
          id
          name
        }
        admin {
          id
          name
          lastname
          profile_image_link
          role_id
          dynamic_labels {
            id
            label
            plural_label
            category
          }
        }

        answers_count
        number_of_students
        course_preview_images {
          id
          link
        }
        start_date
        end_date
        review_avg
        created_at
      }
    }
  `,

    searchCoursesUniversity: gql`
    query coursesByUniversity(
      $search_text: String!
      $newCategoryFilter: ID
      $offset: Int
      $limit: Int
      $admin_ids: [Int]
      $published: Boolean
      $privacy_rule: Int
    ) {
      coursesByUniversity(
        search_text: $search_text
        where: {
          category: $newCategoryFilter
          admin_ids: $admin_ids
          published: $published
          privacy_rule: $privacy_rule
        }
        limit: $limit
        offset: $offset
      ) {
        id
        title
        description
        admissions_fee
        published
        privacy_rule
        lms_course_id
        categories {
          id
          name
        }
        admin {
          id
          name
          lastname
          profile_image_link
          role_id
        }

        answers_count
        number_of_students
        course_preview_images {
          id
          link
        }
        start_date
        end_date
        review_avg
      }
    }
  `,

      searchFeaturedCourses: gql`
    query featuredCoursesInfo($offset: Int, $limit: Int) {
      featuredCoursesInfo(limit: $limit, offset: $offset) {
        id
        title
        description
        default_language
        available_languages
        admissions_fee
        published
        lms_course_id
        categories {
          id
          name
        }
        admin {
          id
          name
          lastname
          profile_image_link
          role_id
          dynamic_labels {
            id
            label
            plural_label
            category
          }
        }

        answers_count
        number_of_students
        course_preview_images {
          id
          link
        }
        start_date
        end_date
        review_avg
        created_at
      }
    }
  `,

        loginMutation: gql`
    mutation signinUser(
      $email: String!
      $password: String!
      $token_id_provider: String
      $provider: String
    ) {
      signinUser(
        email: $email
        password: $password
        token_id_provider: $token_id_provider
        provider: $provider
      ) {
        token
        user {
          id
          email
          name
          lastname
          role_id
          belong_to_user_id
        }
      }
    }
  `,
          signoutUser: gql`
    mutation signoutUser {
      signoutUser {
        status
      }
    }
  `,
            deleteUser: gql`
    mutation deleteUser($id: ID!) {
      deleteUser(id: $id) {
        id
      }
    }
  `,
              removeDocFromVideo: gql`
    mutation removeDocFromVideo($tutorial_doc_id: ID!) {
      removeDocFromVideo(tutorial_doc_id: $tutorial_doc_id)
    }
  `,
                removeImageFromVideo: gql`
    mutation removeImageFromVideo($tutorial_image_id: ID!) {
      removeImageFromVideo(tutorial_image_id: $tutorial_image_id)
    }
  `,
                  removeAudioFromVideo: gql`
    mutation removeAudioFromVideo($tutorial_audio_id: ID!) {
      removeAudioFromVideo(tutorial_audio_id: $tutorial_audio_id)
    }
  `,
                    deleteCourse: gql`
    mutation deleteCourse($id: ID!) {
      deleteCourse(id: $id) {
        id
      }
    }
  `,
                      deleteUserEstablishment: gql`
    mutation deleteUserEstablishment($user_id: ID!) {
      deleteUserEstablishment(user_id: $user_id) {
        id
      }
    }
  `,
                        createUser: gql`
    mutation createUser(
      $email: String!
      $password: String!
      $firstName: String!
      $lastName: String!
      $birthDate: String
      $about: String
      $sex: Sex
      $profileImageLink: String
      $uid: String
      $provider: String
      $role_id: ID
      $belong_to_user_id: ID
      $country: String
    ) {
      createUser(
        email: $email
        password: $password
        name: $firstName
        lastname: $lastName
        birth_date: $birthDate
        about: $about
        sex: $sex
        profile_image_link: $profileImageLink
        uid: $uid
        provider: $provider
        role_id: $role_id
        belong_to_user_id: $belong_to_user_id
        country: $country
      ) {
        id
        email
        lastname
        name
        sex
        role_id
        max_courses
        licenses
        available_courses
        available_licenses
      }
    }
  `,

                          getUniversities: gql`
    query universities($role_id: Int!) {
      allUniversities(role_id: $role_id) {
        id
        email
        lastname
        name
        about
        max_courses
        state
        identity_number
        country
        profile_image_link
        internal_users {
          id
          name
          lastname
          email
          belong_to_user_id
          internal_users {
            id
            name
            lastname
            email
            belong_to_user_id
          }
        }
      }
    }
  `,

                            getUserPendingRequests: gql`
    query getUserPendingRequests($id: ID!) {
      users(where: { id: $id }) {
        pending_requests {
          id
          user {
            id
            name
            lastname
            email
          }
        }
      }
    }
  `,
                              acceptRequest: gql`
    mutation acceptRequest($request_id: ID!) {
      acceptRequest(request_id: $request_id)
    }
  `,
                                rejectRequest: gql`
    mutation rejectRequest($request_id: ID!) {
      rejectRequest(request_id: $request_id)
    }
  `,
                                  createCourse: gql`
    mutation createCourse(
      $title: JSON!
      $description: JSON!
      $student_capacity: Int!
      $categories: [ID]
      $goal: String
      $image_link: String
      $sequential_stages: Boolean!
      $available_throughout_faculty: Boolean
      $student_upload: Boolean!
      $start_date: String
      $end_date: String
    ) {
      createCourse(
        title: $title
        description: $description
        student_capacity: $student_capacity
        categories: $categories
        goal: $goal
        image_link: $image_link
        sequential_stages: $sequential_stages
        available_throughout_faculty: $available_throughout_faculty
        student_upload: $student_upload
        start_date: $start_date
        end_date: $end_date
      ) {
        title
        id
      }
    }
  `,
                                    userImageLink: gql`
    query userImageLink($user_id: ID!, $extension: String!) {
      userImageLink(user_id: $user_id, extension: $extension) {
        acl
        key
        policy
        success_action_status
        url
        x_amz_date
        x_amz_algorithm
        x_amz_signature
        x_amz_credential
      }
    }
  `,
                                      courseImageLink: gql`
    query courseImageLink($course_id: ID!, $extension: String!) {
      courseImageLink(course_id: $course_id, extension: $extension) {
        acl
        key
        policy
        success_action_status
        url
        x_amz_date
        x_amz_algorithm
        x_amz_signature
        x_amz_credential
      }
    }
  `,
                                        editCourse: gql`
    mutation editCourse(
      $id: ID!
      $title: String
      $student_capacity: Int
      $categories: [ID]
      $description: String
      $goal: String
      $image_link: String
    ) {
      editCourse(
        id: $id
        title: $title
        student_capacity: $student_capacity
        categories: $categories
        description: $description
        goal: $goal
        image_link: $image_link
      ) {
        id
        title
        description
        course_preview_images {
          id
          link
        }
      }
    }
  `,
                                          stageFileLink: gql`
    query stageFileLink($stage_id: ID!, $extension: String!) {
      stageFileLink(stage_id: $stage_id, extension: $extension) {
        acl
        key
        policy
        success_action_status
        url
        x_amz_date
        x_amz_algorithm
        x_amz_signature
        x_amz_credential
      }
    }
  `,
                                            editUser: gql`
    mutation editUser(
      $user_id: ID!
      $password: String
      $name: String
      $lastname: String
      $birth_date: String
      $locale: String
      $about: String
      $sex: Sex
      $profile_image_link: String
      $email: String
      $country: String
      $state: String
      $identity_number: String
      $color: String
      $image: String
      $max_courses: Int
      $curriculum_file: String
      $institution: String
      $academic_degree: String
      $profession: String
      $linkedin: String
      $work_place: String
      $authorized_create_course: Boolean
    ) {
      editUser(
        user_id: $user_id
        password: $password
        name: $name
        lastname: $lastname
        birth_date: $birth_date
        locale: $locale
        about: $about
        sex: $sex
        email: $email
        profile_image_link: $profile_image_link
        country: $country
        state: $state
        identity_number: $identity_number
        color: $color
        image: $image
        max_courses: $max_courses
        curriculum_file: $curriculum_file
        institution: $institution
        academic_degree: $academic_degree
        profession: $profession
        linkedin: $linkedin
        work_place: $work_place
        authorized_create_course: $authorized_create_course
      ) {
        id
        name
        lastname
        about
        sex
        birth_date
        locale
        profile_image_link
        curriculum_file
        institution
        academic_degree
        profession
        linkedin
        work_place
        authorized_create_course
      }
    }
  `,

                                              editLicenseUniversities: gql`
    mutation editLicenseUniversities(
      $university_id: ID!
      $licenses: Int!
      $max_courses: Int!
    ) {
      editLicenseUniversities(
        university_id: $university_id
        licenses: $licenses
        max_courses: $max_courses
      ) {
        id
      }
    }
  `,

                                                editLicensesByRole: gql`
    mutation editLicensesByRole(
      $user_id: ID!
      $licenses: Int!
      $max_courses: Int!
    ) {
      editLicensesByRole(
        user_id: $user_id
        licenses: $licenses
        max_courses: $max_courses
      ) {
        id
      }
    }
  `,
                                                  addVideoToStage: gql`
    mutation addVideoToStage(
      $stage_id: ID!
      $link: String!
      $title: String
      $description: String
    ) {
      addVideoToStage(
        stage_id: $stage_id
        link: $link
        title: $title
        description: $description
      ) {
        id
        link
      }
    }
  `,
                                                    getStage: gql`
    query getStage($id: ID!) {
      Stage(id: $id) {
        id
        title
        description
        tutorial_videos {
          title
          link
          updated_at
          tutorial_docs {
            id
            title
            link
          }
          tutorial_audios {
            id
            title
            link
          }
          tutorial_images {
            id
            title
            link
          }
        }
      }
    }
  `,
                                                      addFileToStageVideo: gql`
    mutation addFileToStageVideo(
      $video_id: ID!
      $link: String!
      $title: String
    ) {
      addFileToStageVideo(video_id: $video_id, link: $link, title: $title)
    }
  `,
                                                        requestCourse: gql`
    mutation requestCourse($course_id: ID!) {
      requestCourse(course_id: $course_id) {
        id
      }
    }
  `,
                                                          getCourseStagesVideo: gql`
    query getCourseStagesVideo($id: ID!) {
      stages(where: { id: $id }) {
        id
        title
        description
        tutorial_videos {
          updated_at
          link
        }
      }
    }
  `,
                                                            getUserInfo: gql`
    query getUserInfo($userId: ID) {
      users(where: { id: $userId }) {
        id
        work_place
        birth_date
        available_courses
        accepted_terms
        locale
        email
        name
        lastname
        role
        profession
        institution
        academic_degree
        about
        linkedin
        max_courses
        licenses
        curriculum_file
        profile_image_link
        authorized_create_course
        image
        address
        role_id
        belong_to_user_id
      }
    }
  `,
                                                              getCoursesRequests: gql`
    query getCoursesRequests($courseId: ID) {
      courses(where: { id: $courseId }) {
        pending_requests {
          id
          user {
            id
            name
            lastname
            email
          }
        }
      }
    }
  `,
                                                                getOwnCourses: gql`
    query getOwnCourses {
      viewerInfo {
        own_courses {
          id
        }
      }
    }
  `,
                                                                  allCourseByUserRole: gql`
    query AllCoursesByUserRole(
      $search_text: String
      $where: CourseByUserRoleFilterProvider
      $offset: Int
      $limit: Int
      $belong_to_user_id: Int
    ) {
      coursesByUserRole(
        search_text: $search_text
        where: $where
        limit: $limit
        offset: $offset
        belong_to_user_id: $belong_to_user_id
      ) {
        id
        title
        id
        title
        description
        admissions_fee
        published
        privacy_rule
        default_language
        available_languages
        created_at
        lms_course_id
        categories {
          id
          name
        }
        admin {
          id
          name
          lastname
          profile_image_link
          role
          role_id
        }
        answers_count
        number_of_students
        course_preview_images {
          id
          link
        }
        start_date
        end_date
        review_avg
      }
    }
  `,
  dynamicLabels: gql`
    query viewerInfo {
      viewerInfo {
        id
        dynamic_labels {
          id
          label
          plural_label
          category
        }
        onboarding
      }
    }
  `,
  viewerInfo: gql`
    query viewerInfo {
      viewerInfo {
        id
        profile_image_link
        curriculum_file
        institution
        academic_degree
        profession
        linkedin
        work_place
        birth_date
        available_courses
        accepted_terms
        locale
        passed_courses {
          id
        }
        current_courses {
          title
          id
        }
        own_courses {
          title
          id
        }
        unpublished_courses {
          title
          id
        }
        taught_courses {
          title
          id
        }
        in_training_courses {
          title
          id
        }
        name
        is_superadmin
        role
        role_id
        lastname
        email
        birth_date
        sex
        about
        state
        country
        identity_number
        belong_to_user_id
        onboarding
      }
    }
  `,
                                                                      profileInfo: gql`
    query viewerInfo {
      viewerInfo {
        id
        profile_image_link
        curriculum_file
        institution
        academic_degree
        profession
        linkedin
        work_place
        birth_date
        accepted_terms
        name
        lastname
        is_superadmin
        email
        birth_date
        sex
        about
        state
        country
        identity_number
        image
        address
        max_courses
        licenses
        available_courses
        available_licenses
        presentation_video
        role_id
        belong_to_user_id
      }
    }
  `,
                                                                        getStageExercise: gql`
    query getStageExercise($exerciseId: ID) {
      exercises(where: { id: $exerciseId }) {
        id
        description
        evaluation_items {
          id
          name
          scale {
            id
            description
            max_value
            min_value
            name
            required_value
            compound_score,
            compound_score_achievements_associated
            compound_score_percentage_for_achievement
            compound_score_percentage
          }
        }
      }
    }
  `,
                                                                          createAnswer: gql`
    mutation createAnswer($exerciseID: ID!, $videoLink: String!, $userID: ID) {
      createAnswer(
        exercise_id: $exerciseID
        video_link: $videoLink
        user_id: $userID
      ) {
        id
      }
    }
  `,
                                                                            evaluationVideoLink: gql`
    query evaluationVideoLink(
      $exerciseID: ID!
      $extension: String!
      $userID: ID
    ) {
      evaluationVideoLink(
        exercise_id: $exerciseID
        extension: $extension
        user_id: $userID
      ) {
        acl
        key
        policy
        success_action_status
        url
        x_amz_date
        x_amz_algorithm
        x_amz_signature
        x_amz_credential
      }
    }
  `,
                                                                              getCheckUserView: gql`
    query getCheckUserView($user_id: ID!, $view: String!) {
      checkUserView(user_id: $user_id, view: $view) {
        user_id
        readyTutorial
        view
      }
    }
  `,
                                                                                getAnswerVideo: gql`
    query getAnswerVideo($exerciseID: ID!) {
      answers(where: { exercise_id: $exerciseID }) {
        video_link
      }
    }
  `,
                                                                                  addTeacher: gql`
    mutation addTeacherToCourse($user_id: ID, $email: String, $course_id: ID!) {
      addTeacherToCourse(
        user_id: $user_id
        email: $email
        course_id: $course_id
      )
    }
  `,
                                                                                    ownFeedback: gql`
    query ownFeedback($exerciseID: ID) {
      ownFeedback(where: { exercise_id: $exerciseID }) {
        answer_id
      }
    }
  `,
                                                                                      generateAwsKeys: gql`
    query generateS3AccessCredentials {
      generateS3AccessCredentials {
        access_key_id
        secret_access_key
        session_token
      }
    }
  `,
                                                                                        createConvertJob: gql`
    mutation createConvertJob($s3Location: String!) {
      createConvertJob(s3_location: $s3Location)
    }
  `,
                                                                                          createTranscodeJob: gql`
    mutation createTranscodeJob($s3Location: String!) {
      createTranscodeJob(s3_location: $s3Location)
    }
  `,
                                                                                            courseByUniversity: gql`
    query courseByUniversity($text: String) {
      coursesByUniversity(text: $text) {
        id
        title
        published
        privacy_rule
        course_preview_images {
          id
          link
          title
        }
        categories {
          id
          name
        }
        answers_count
        number_of_students
        description
        id
      }
    }
  `,
                                                                                              allCourses: gql`
    query allCourses {
      allCoursesBasic {
        id
        title
        admin {
          name
          lastname
        }
      }
    }
  `,
                                                                                                superadminAllUser: gql`
    query superadminAllUser {
      allUsers: SuperAdminUserByType(type: "ALL")
    }
  `,
                                                                                                  createPreference: gql`
    mutation createPaymentPreference($course_id: ID!) {
      createPaymentPreference(course_id: $course_id)
    }
  `,
                                                                                                    addDiscountCode: gql`
    mutation addDiscountCode($course_id: ID!, $discountCode: String!) {
      addDiscountToCourse(course_id: $course_id, discount_code: $discountCode) {
        id
        code
        description
        max_courses
        percentage_amount_to_pay
      }
    }
  `,
                                                                                                      performanceUserInCourse: gql`
    query performanceUserInCourse($course_id: ID!, $user_id: ID) {
      performanceUserInCourse(course_id: $course_id, user_id: $user_id) {
        passed
        stage {
          title
          position
          exercise {
            evaluation_type
          }
          id
        }
        rating
      }
    }
  `,

                                                                                                        allUniversities: gql`
    query universities($role_id: Int, $belong_to_user_id: Int) {
      allUniversities(
        role_id: $role_id
        belong_to_user_id: $belong_to_user_id
      ) {
        id
        email
        name
        lastname
        profession
        institution
        academic_degree
        about
        linkedin
        max_courses
        licenses
        curriculum_file
        profile_image_link
        authorized_create_course
        image
        address
        role_id
        belong_to_user_id
        identity_number
        country
        state
        color
        available_courses
        available_licenses
        internal_users {
          id
          email
          name
          lastname
          profession
          institution
          academic_degree
          about
          linkedin
          max_courses
          licenses
          curriculum_file
          profile_image_link
          authorized_create_course
          image
          address
          role_id
          belong_to_user_id
          identity_number
          country
          state
          color
          available_courses
          available_licenses
          internal_users {
            id
            email
            name
            lastname
            profession
            institution
            academic_degree
            about
            linkedin
            max_courses
            licenses
            curriculum_file
            profile_image_link
            authorized_create_course
            image
            address
            role_id
            belong_to_user_id
            identity_number
            country
            color
            state
            available_courses
            available_licenses
          }
        }
      }
    }
  `,
                                                                                                          universityInfo: gql`
    query universityInfo($id: ID!) {
      universityInfo(id: $id) {
        id
        email
        name
        lastname
        role
        accepted_terms
        profession
        institution
        academic_degree
        about
        linkedin
        max_courses
        licenses
        curriculum_file
        profile_image_link
        presentation_video
        authorized_create_course
        image
        address
        role_id
        belong_to_user_id
        identity_number
        country
        state
        color
        presentation_video
        dynamic_labels {
          id
          label
          plural_label
          category
        }
        father_user {
          id
          role_id
          name
          color
          presentation_video
          father_user {
            id
            role_id
            name
            presentation_video
          }
        }
      }
    }
  `,
                                                                                                            getPendingAnswers: gql`
    query pendingAnswers($course_id: ID) {
      pendingAnswers(course_id: $course_id)
    }
  `,
                                                                                                              getPendingAnswersEveryoneEvaluate: gql`
    query numberPendingAnswersEveryoneEvaluateCourse($course_id: ID) {
      numberPendingAnswersEveryoneEvaluateCourse(course_id: $course_id)
    }
  `,
                                                                                                                getUserCourseStatusStage: gql`
    query getUserCourseStatusStage($course_id: ID!, $user_id: ID) {
      getUserCourseStatusStage(course_id: $course_id, user_id: $user_id) {
        evaluation_type
        status
        result
        number_attempt
        answer_id
        stage_id
        user_course_id
      }
    }
  `,
                                                                                                                  getFavouriteTeachers: gql`
    query getFavouriteTeachers {
      getFavouriteTeachers {
        profile_image_link
      }
    }
  `,

                                                                                                                    getUserRequests: gql`
    query getUserRequests($courseID: ID!) {
      requests(course_id: $courseID) {
        id
        status
      }
    }
  `,

                                                                                                                      feedbackEveryoneEvaluateByUser: gql`
    query feedbackEveryoneEvaluateByUser($answer_id: ID!, $course_id: ID!) {
      feedbackEveryoneEvaluateByUser(
        answer_id: $answer_id
        course_id: $course_id
      ) {
        id
        everyone_evaluate_answer {
          user_id
        }
        answer {
          user_id
        }
        finished
        approved
        is_open_feedback
        teacher {
          id
          name
          lastname
          email
          profile_image_link
         
        }
        feedback_texts {
          id
          text
          time
        }
        feedback_audios {
          id
          link
          time
        }
        feedback_drawings {
          id
          link
          time
        }
        feedback_common_mistakes {
          id
          time
          common_mistake {
            id
            title
            description
            link
            video_duration
            video_size
            video_size_without_compress
          }
        }
        feedback_feedback {
          id
          score
          comments
          technical_quality_comments
        }
        feedback_scores {
          id
          score
          evaluation_item {
            id
            name
            descriptions
            descriptions_values
            min_value
            max_value
            req_value
            scale_id
            position
            aditional_text
            
          }
        }
      }
    }
  `,
                                                                                                                        everyoneEvaluateFeedbacksAndScoresByAnswer: gql`
    query everyoneEvaluateFeedbacksAndScoresByAnswer(
      $answer_id: ID!
      $course_id: ID!
    ) {
      everyoneEvaluateFeedbacksAndScoresByAnswer(
        answer_id: $answer_id
        course_id: $course_id
      ) {
        texts
        audios
        drawings
        commonMistakes
        avg_evaluation_items
        status_stage
      }
      everyoneEvaluateTeacherAssignmentStatusByAnswer(
        answer_id: $answer_id
        course_id: $course_id
      )
    }
  `,

                                                                                                                          createAnswerToEveryoneCanEvaluateCourse: gql`
    mutation createAnswerToEveryoneCanEvaluateCourse(
      $course_id: ID!
      $answer_id: ID!
    ) {
      createAnswerToEveryoneCanEvaluateCourse(
        answer_id: $answer_id
        course_id: $course_id
      )
    }
  `,
                                                                                                                            everyoneEvaluateInfo: gql`
    query everyoneEvaluateInfo($stage_id: ID!) {
      everyoneEvaluateInfo(stage_id: $stage_id) {
        id
        stage_id
        evaluations_needed
        is_teacher_definitive
        evaluation_waiting_days
        is_automatic_assignment
      }
    }
  `,
                                                                                                                              editEveryoneEvaluateByStage: gql`
    mutation editEveryoneEvaluateByStage(
      $stage_id: ID!
      $evaluations_needed: Int!
      $is_teacher_definitive: Boolean!
      $is_automatic_assignment: Boolean!
      $evaluation_waiting_days: Int
    ) {
      editEveryoneEvaluateByStage(
        stage_id: $stage_id
        evaluations_needed: $evaluations_needed
        is_teacher_definitive: $is_teacher_definitive
        evaluation_waiting_days: $evaluation_waiting_days
        is_automatic_assignment: $is_automatic_assignment
      ) {
        id
        evaluations_needed
        is_teacher_definitive
        evaluation_waiting_days
        is_automatic_assignment
      }
    }
  `,
                                                                                                                                editCoursePresentationVideoSize: gql`
    mutation editCoursePresentationVideoSize(
      $course_id: ID!
      $video_size: Float!
    ) {
      editCoursePresentationVideoSize(
        course_id: $course_id
        video_size: $video_size
      )
    }
  `,
                                                                                                                                  editCommonMistakeVideoSize: gql`
    mutation editCommonMistakeVideoSize(
      $common_mistake_id: ID!
      $video_size: Float!
    ) {
      editCommonMistakeVideoSize(
        common_mistake_id: $common_mistake_id
        video_size: $video_size
      )
    }
  `,

                                                                                                                                    everyoneEvaluateTeacherAssignmentInfo: gql`
    query everyoneEvaluateTeacherAssignmentInfo($stage_id: ID!) {
      everyoneEvaluateTeacherAssignmentInfo(stage_id: $stage_id)

      everyoneEvaluateTeacherAssignmentAnswersStatus(stage_id: $stage_id)
    }
  `,
                                                                                                                                      getIsEveryoneEvaluateStage: gql`
    query getIsEveryoneEvaluateStage($stage_id: ID!) {
      getIsEveryoneEvaluateStage(stage_id: $stage_id)
    }
  `,
                                                                                                                                        addEveryoneEvaluateTeacherAssignment: gql`
    mutation addEveryoneEvaluateTeacherAssignment(
      $stage_id: ID!
      $teacher_id: ID!
      $student_id: ID!
    ) {
      addEveryoneEvaluateTeacherAssignment(
        stage_id: $stage_id
        teacher_id: $teacher_id
        student_id: $student_id
      ) {
        id
      }
    }
  `,
                                                                                                                                          removeEveryoneEvaluateTeacherAssignment: gql`
    mutation removeEveryoneEvaluateTeacherAssignment(
      $stage_id: ID!
      $teacher_id: ID!
      $student_id: ID!
    ) {
      removeEveryoneEvaluateTeacherAssignment(
        stage_id: $stage_id
        teacher_id: $teacher_id
        student_id: $student_id
      )
    }
  `,

                                                                                                                                            ltiSignInUser: gql`
    query ltiSignInUser(
      $email: String!
      $family_name: String!
      $name: String!
      $roles: JSON!
      $lms_course_id: String!
      $lms_course_label: String!
      $lms_course_title: String!
      $client_id: String!
      $platform_id: String!
    ) {
      ltiSignInUser(
        user_info: { email: $email, family_name: $family_name, name: $name }
        lms_course_id: $lms_course_id
        lms_course_label: $lms_course_label
        lms_course_title: $lms_course_title
        client_id: $client_id
        platform_id: $platform_id
        roles: $roles
      ) {
        token
        courses {
          id
          title
        }
        university_user_id
        user {
          id
          email
          name
          lastname
          role_id
        }
      }
    }
  `,

                                                                                                                                              allEstablishmentByUser: gql`
    query allEstablishmentByUser($belong_to_user_id: Int) {
      allEstablishmentByUser(belong_to_user_id: $belong_to_user_id) {
        id
        name
        role_id
        internal_users {
          id
          name
          role_id
        }
      }
    }
  `,

                                                                                                                                                getUsersByRole: gql`
    query getUsersByRole($role_id: Int!) {
      allUniversities(role_id: $role_id) {
        id
        name
        internal_users {
          id
          name
          internal_users {
            id
            name
            internal_users {
              id
              name
            }
          }
        }
      }
    }
  `,
                                                                                                                                                  allHeadquarter: gql`
    query allHeadquarterByUser($user_id: Int!) {
      allHeadquarterByUser(user_id: $user_id) {
        id
        name
        initials
      }
    }
  `,
                                                                                                                                                    allAcademicPeriods: gql`
    query allAcademicPeriodsByUser($user_id: Int!) {
      allAcademicPeriodsByUser(user_id: $user_id) {
        id
        name
      }
    }
  `,

  createAnswerToEveryoneCanEvaluateCourse: gql`
  mutation createAnswerToEveryoneCanEvaluateCourse(
    $course_id: ID!
    $answer_id: ID!
  ) {
    createAnswerToEveryoneCanEvaluateCourse(
      answer_id: $answer_id
      course_id: $course_id
    )
  }
`,
getAchievementReportByCourse: gql`
    query getAchievementReportByCourse($course_id: ID!) {
      getAchievementReportByCourse(course_id: $course_id)
    }`,

deleteUser: gql`
  mutation removeUser($user_id: Int!){
    removeUser(id: $user_id)
  }
  `,

getAchievementReportByUniversity: gql`
  query getAchievementReportByUniversity(
    $year: Int,
    $academic_period_id: ID, 
    $headquarter_id: ID,  
    $career_id: ID,  
    $course_id: ID,  
    $faculty_id: ID,
   
  ) {
    achievementIndicatorDashboard(
      year: $year,
      academic_period_id: $academic_period_id, 
      headquarter_id: $headquarter_id,  
      career_id: $career_id,  
      course_id: $course_id,  
      faculty_id: $faculty_id,
     

    )
  }
`,
}
export default queries;
